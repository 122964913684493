.landing-swiper {
    width: 100%;
    height: 100%;
}

.landing-slide {
    margin: 0 !important;
}

.swiper-pagination-bullets {
    bottom: 1.5rem !important;
}